/* eslint-disable import/order */
import { registerLocaleData } from '@angular/common';
import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import {
  ErrorHandler,
  NgModule,
  inject,
  isDevMode,
  provideAppInitializer
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { createErrorHandler } from '@sentry/angular';
import { providePrimeNG } from 'primeng/config';
import { lastValueFrom } from 'rxjs';

import { environment } from '@company/environments/environment';
import { LaenkPreset } from '@company/styles/primeng_custom';

import packageJson from '../../package.json';
import {
  AppRoutingModule,
  TemplatePageTitleStrategy
} from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardHeaderDirective } from './directives/dashboard-header/dashboard-header.directive';
import { InterceptorProviders } from './interceptors';
import { ImpersonationRibbonModule } from './modules/shared/impersonation-ribbon/impersonation-ribbon.module';
import { SpinnyModule } from './modules/shared/spinny/spinny.module';
import { CustomMissingTranslationHandler } from './modules/shared/translations/missing-translation.handler';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de', localeDeExtra);

export function HttpLoaderFactory(
  httpBackend: HttpBackend
): TranslateHttpLoader {
  let suffix = '.json';

  // Use the version from package.json to avoid caching issues
  if (!isDevMode()) {
    suffix += `?v=${packageJson.version}`;
  }

  return new TranslateHttpLoader(
    new HttpClient(httpBackend),
    './assets/i18n/',
    suffix
  );
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    const userLocale =
      navigator.language ||
      (navigator.languages.length && navigator.languages[0]) ||
      'de-DE';
    const currentLang = userLocale.split(/-|_/)[0];
    translate.setDefaultLang(environment.app.defaultLanguage);

    return lastValueFrom(translate.use(currentLang));
  };
}

@NgModule({
  declarations: [AppComponent, DashboardHeaderDirective],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ImpersonationRibbonModule,
    SpinnyModule,
    LoadingBarRouterModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.app.defaultLanguage,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    provideAppInitializer(() => {
      const initializerFn = appInitializerFactory(inject(TranslateService));
      return initializerFn();
    }),
    InterceptorProviders,
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    {
      provide: 'googleTagManagerId',
      useValue: environment.app.googleTagManagerId
    },
    provideHttpClient(withInterceptorsFromDi()),
    providePrimeNG({
      theme: {
        preset: LaenkPreset,
        options: {
          darkModeSelector: false
        }
      }
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler()
    }
  ]
})
export class AppModule {}
