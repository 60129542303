import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, Subscription } from 'rxjs';

import { Constants } from './models/constant';
import { AuthService } from './services/auth/auth.service';
import { BroadcastService } from './services/broadcast/broadcast.service';
import { ConstantService } from './services/constant/constant.service';
import { ProfileService } from './services/profile/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  impersonationInterval?: unknown;

  userProfileSub?: Subscription;

  constants$!: Observable<Constants>;

  constructor(
    public loadingBarService: LoadingBarService,
    private gtmService: GoogleTagManagerService,
    private broadcastService: BroadcastService,
    public readonly authService: AuthService,
    private readonly profileService: ProfileService,
    private readonly constantService: ConstantService
  ) {}

  ngOnInit(): void {
    this.userProfileSub = this.profileService.fetchProfile().subscribe();

    this.broadcastService.handleAuthenticationMessages(); // handles auth on different tabs
    this.gtmService.addGtmToDom();

    this.updateImpersonationStatus();

    this.constants$ = this.constantService.fetchConstants();
  }

  private updateImpersonationStatus() {
    this.authService.checkIfUserIsImpersonating();

    this.impersonationInterval = setInterval(() => {
      this.authService.checkIfUserIsImpersonating();
    }, 7000);
  }

  ngOnDestroy(): void {
    clearInterval(this.impersonationInterval as number);

    this.userProfileSub?.unsubscribe();
  }
}
