import { isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import packageJson from '../package.json';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.app.logErrors)
  Sentry.init({
    dsn: 'https://3c81409c68e3420ea551e45d2da32f4c@o433575.ingest.sentry.io/4505236920532992',
    integrations: [
      Sentry.browserTracingIntegration({
        instrumentNavigation: false
      }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration()
    ],
    environment: environment?.app?.sentryEnvironment,

    // Performance Monitoring
    // Capture 100% of the transactions, reduce in production!
    tracesSampleRate: isDevMode() ? 1.0 : 0.05,

    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: isDevMode() ? 1.0 : 0.01,

    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: isDevMode() ? 1.0 : 0.1,

    release: packageJson.version
  });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
