import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

const LaenkPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '#6633ff',
      100: '#6633ff',
      200: '#6633ff',
      300: '#6633ff',
      400: '#6633ff',
      500: '#6633ff',
      600: '#6633ff',
      700: '#6633ff',
      800: '#6633ff',
      900: '#6633ff',
      950: '#6633ff'
    }
  }
});

export { LaenkPreset };
