{
  "name": "@laenk/company-app",
  "version": "2.0.0",
  "release-it": {
    "git": {
      "requireBranch": "release/*",
      "requireCleanWorkingDir": true,
      "requireUpstream": false
    },
    "npm": {
      "publish": false
    }
  }
}
