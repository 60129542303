import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, map, switchMap, tap } from 'rxjs';

import { Constants, ExtendedConstantType } from '@company/app/models/constant';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  constants$ = new BehaviorSubject<Constants | undefined>(undefined);

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService
  ) {}

  fetchConstants(): Observable<Constants> {
    return this.http
      .get<Constants>('common/constants/', {
        params: {
          lang: this.translateService.currentLang
        }
      })
      .pipe(
        switchMap((constants) => {
          return this.http
            .get<Constants>('common/constants/', {
              params: {
                choice: [
                  ExtendedConstantType.extended_care_type,
                  ExtendedConstantType.extended_continuing_education,
                  ExtendedConstantType.extended_education,
                  ExtendedConstantType.extended_position
                ].join(',')
              }
            })
            .pipe(
              map((extendedConstants) => {
                return {
                  ...constants,
                  ...extendedConstants
                };
              })
            );
        }),
        tap((constants) => this.constants$.next(constants))
      );
  }
}
