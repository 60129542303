import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, throwError } from 'rxjs';

import { ToastService } from '@company/app/services/toast/toast.service';
import { environment } from '@company/environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  private handleErrors(err: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${
        err.status
      }, body was: ${JSON.stringify(err.error)}`;
    }

    // check for 404
    if (err.status === 404) {
      errorMessage = this.translateService.instant('toasts.general.notFound');

      if (err.url?.includes('/magic-link')) {
        errorMessage = '';
        window.location.assign(
          'https://kontakt.laenk.de/login-unternehmen#start'
        );
      }
      if (errorMessage) this.toastService.danger(errorMessage);
    }

    if (err.status === 403) {
      errorMessage = this.translateService.instant(
        'toasts.general.permissionDenied'
      );
      this.toastService.danger(errorMessage);
    }

    if (errorMessage) console.error(errorMessage);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return throwError(() => new HttpErrorResponse(err as any));
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const apiRequest = request.clone({
      url: environment.api.baseUrl + request.url
    });

    return next.handle(apiRequest).pipe(
      catchError((err) => {
        return this.handleErrors(err);
      })
    );
  }
}
